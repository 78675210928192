import { Box, Divider, Flex, HStack } from "@chakra-ui/react"
import { BerthCategories } from "@type/directus"

interface MergedCategory
  extends Omit<
    BerthCategories,
    "dimensions_width" | "dimensions_height" | "dimensions_weight"
  > {
  dimension: {
    dimensions_width: string
    dimensions_height: string
    dimensions_weight: string
  }[]
}

const Legends = ({ categories }: { categories: BerthCategories[] }) => {
  const mergedCategories: MergedCategory[] = Object.values(
    categories.reduce(
      (acc, category) => {
        const {
          name,
          dimensions_width,
          dimensions_height,
          dimensions_weight,
          ...rest
        } = category
        const key = name

        if (!acc[key]) {
          acc[key] = {
            name,
            dimension: [
              {
                dimensions_width,
                dimensions_height,
                dimensions_weight,
              },
            ],
            ...rest,
          }
        } else {
          acc[key].dimension.push({
            dimensions_width,
            dimensions_height,
            dimensions_weight,
          })
        }

        return acc
      },
      {} as { [key: string]: MergedCategory }
    )
  )

  return (
    <Flex
      alignItems={"flex-start"}
      justifyContent={"center"}
      flexWrap={"wrap"}
      m={4}
    >
      {mergedCategories.map((category, index) => (
        <Box
          fontWeight={"500"}
          me={4}
          key={index}
        >
          <Box
            textAlign={"center"}
            textTransform={"uppercase"}
          >
            {category.name}
          </Box>
          <Flex
            flexDirection={"column"}
            bgColor={`${category.color}`}
            fontSize={{ base: "xs", md: "sm" }}
            p={{ base: 2, md: 4 }}
          >
            {category.dimension.map((dimension, i) => (
              <HStack key={i}>
                <Box>{`W ${dimension.dimensions_width} M`}</Box>
                <Box>{`H ${dimension.dimensions_height} M`}</Box>
                <Divider
                  orientation={"vertical"}
                  borderColor={"blackAlpha.500"}
                  h={4}
                />
                <Box>{`${Number(
                  dimension.dimensions_weight
                ).toLocaleString()} KG`}</Box>
              </HStack>
            ))}
          </Flex>
        </Box>
      ))}
    </Flex>
  )
}

export default Legends
