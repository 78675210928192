import {
  Badge,
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  GridItemProps,
  Heading,
  Icon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useDisclosure,
  useToken,
  VStack,
} from "@chakra-ui/react"
import H1 from "@components/Headings/H1"
import BrandButton from "@components/Links/BrandButton"
import { useStackContext } from "@context/StackContext"
import ArrowSvg from "@public/images/arrow.svg"
import BoatFront from "@public/images/boat-front.svg"
import BoatLayout from "@public/images/boat-layout.svg"
import { BerthCategories, Berths, BerthTerms } from "@type/directus"
import { useRouter } from "next/router"
import { useSession } from "next-auth/react"
import { FiCheck, FiX } from "react-icons/fi"

interface StackGridItemProps extends GridItemProps {
  stack: Berths
  terms: BerthTerms
}

const StackGridItem = ({ stack, terms, ...props }: StackGridItemProps) => {
  const { status } = useSession()
  const { updateStackData } = useStackContext()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const category = stack.category as BerthCategories
  const [brand300, gray100] = useToken("colors", ["brand.300", "gray.100"])
  const router = useRouter()
  const bookOnlineHandler = () => {
    updateStackData({
      berth: stack,
    })
    router.push(status === "authenticated" ? "/applications/new" : "/signin")
  }

  return (
    <GridItem
      borderBottomColor={`${category?.color}`}
      borderBottomWidth={8}
      borderBottomStyle={"solid"}
      bgColor={"gray.50"}
      fontSize={"sm"}
      color={"black"}
      w={{ base: 16, md: 32 }}
      position={"relative"}
      _hover={{
        bgColor: stack.name ? "brand.800" : "gray.50",
        color: "white",
      }}
      role={"group"}
      {...props}
    >
      {stack.status !== "not_sellable" && (
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          flexDirection={"column"}
          _groupHover={{
            cursor: "pointer",
          }}
          onClick={onOpen}
          height={"full"}
        >
          <Icon
            as={ArrowSvg}
            color={"white"}
            w={6}
            h={6}
            display={"none"}
            position={"absolute"}
            right={2}
            top={2}
            _groupHover={{
              display: "block",
            }}
          />
          <Box
            fontSize={{ base: "xs", md: "md" }}
            pt={2}
          >
            {stack.name}
          </Box>
          {stack.status === "reserved" ? (
            <Badge colorScheme={"green"}>{stack.status}</Badge>
          ) : stack.status === "occupied" ? (
            <Badge colorScheme={"blue"}>{stack.status}</Badge>
          ) : null}

          <Icon
            as={BoatFront}
            mt={6}
            color={stack.status === "occupied" ? "brand.800" : "gray.300"}
            w={{ base: 8, md: 16 }}
            h={{ base: 8, md: 16 }}
          />
        </Flex>
      )}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={"full"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            bgColor={"brand.800"}
            color={"white"}
            borderRadius={"none"}
            m={{ base: 4, md: 10 }}
          />
          <ModalBody
            bgColor={"brand.50"}
            display={"flex"}
            justifyContent={"center"}
          >
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
              }}
              templateRows={{
                base: "auto 1fr",
                md: "repeat(1, 1fr)",
              }}
              justifyContent={"center"}
              alignItems={"start"}
              w={"full"}
            >
              <GridItem minW={"100%"}>
                <Icon
                  as={BoatLayout}
                  color={"brand.800"}
                  w={"full"}
                  h={"full"}
                  mt={{ base: 12, md: 0 }}
                  p={{ base: 4, md: 20 }}
                />
              </GridItem>
              <GridItem minW={"100%"}>
                <VStack
                  alignItems={{ base: "center", md: "start" }}
                  mt={10}
                >
                  <H1
                    mt={0}
                    mb={10}
                    borderBottomColor={`${category?.color}`}
                    borderBottomWidth={8}
                    textAlign={{ base: "center", md: "start" }}
                  >
                    <Box
                      as={"span"}
                      fontSize={"md"}
                      display={"block"}
                    >
                      {stack.name}
                    </Box>
                    {category?.name}
                  </H1>
                  <Box>
                    {"Width: "}
                    <Box
                      as={"span"}
                      fontSize={"2xl"}
                      ms={2}
                    >
                      {`${category?.dimensions_width}M`}
                    </Box>
                  </Box>
                  <Divider />
                  <Box>
                    {"Height: "}
                    <Box
                      as={"span"}
                      fontSize={"2xl"}
                      ms={2}
                    >
                      {`${category?.dimensions_height}M`}
                    </Box>
                  </Box>
                  <Divider />
                  <Box>
                    {"Weight: "}
                    <Box
                      as={"span"}
                      fontSize={"2xl"}
                      ms={2}
                    >
                      {`${Number(
                        category?.dimensions_weight
                      )?.toLocaleString()}KG`}
                    </Box>
                  </Box>
                  <Divider />
                  <TableContainer
                    pb={4}
                    sx={{
                      scrollbarColor: `${brand300} transparent`,
                    }}
                    css={{
                      "&::-webkit-scrollbar": {
                        height: "8px",
                      },
                      "&::-webkit-scrollbar-track": {
                        height: "6px",
                        bgColor: gray100,
                      },
                      "&::-webkit-scrollbar-thumb": {
                        bgColor: brand300,
                        borderRadius: "2px",
                      },
                    }}
                  >
                    <Table
                      size={"sm"}
                      variant={"striped"}
                      colorScheme={"blackAlpha"}
                    >
                      <TableCaption
                        placement={"top"}
                        textTransform={"uppercase"}
                      >
                        {`Berthing Rates ${new Date().getFullYear()}`}
                      </TableCaption>
                      <Thead>
                        <Tr>
                          <Th colSpan={category?.rate_premium ? 1 : 3} />
                          {category?.rate_premium && (
                            <>
                              <Th textAlign={"center"}>{"Standard"}</Th>
                              <Th textAlign={"center"}>{"Premium"}</Th>
                            </>
                          )}
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>{"Rate"}</Td>
                          <Td
                            textAlign={"center"}
                            colSpan={category?.rate_premium ? 1 : 2}
                          >
                            {category?.rate_standard}
                          </Td>
                          {category?.rate_premium && (
                            <Td textAlign={"center"}>
                              {category?.rate_premium}
                            </Td>
                          )}
                        </Tr>
                        <Tr>
                          <Td>{"Location"}</Td>
                          <Td
                            textAlign={"center"}
                            colSpan={category?.rate_premium ? 1 : 2}
                          >
                            {category?.location}
                          </Td>
                          {category?.rate_premium && (
                            <Td textAlign={"center"}>{category?.location}</Td>
                          )}
                        </Tr>
                        <Tr>
                          <Td>{"Max. LOA"}</Td>
                          <Td
                            textAlign={"center"}
                            colSpan={category?.rate_premium ? 1 : 2}
                          >{`${category?.max_loa}ft`}</Td>
                          {category?.rate_premium && (
                            <Td
                              textAlign={"center"}
                            >{`${category?.max_loa}ft`}</Td>
                          )}
                        </Tr>
                        <Tr>
                          <Td>{"Fixed Canopy & Anchor Light"}</Td>
                          <Td
                            textAlign={"center"}
                            colSpan={category?.rate_premium ? 1 : 2}
                          >
                            <Icon
                              as={
                                category?.fixed_canopy_anchor_light
                                  ? FiCheck
                                  : FiX
                              }
                            />
                          </Td>
                          {category?.rate_premium && (
                            <Td textAlign={"center"}>
                              <Icon
                                as={
                                  category?.fixed_canopy_anchor_light
                                    ? FiCheck
                                    : FiX
                                }
                              />
                            </Td>
                          )}
                        </Tr>
                        <Tr>
                          <Td>{"Foldable Canopy"}</Td>
                          <Td
                            textAlign={"center"}
                            colSpan={category?.rate_premium ? 1 : 2}
                          >
                            <Icon
                              as={category?.foldable_canopy ? FiCheck : FiX}
                            />
                          </Td>
                          {category?.rate_premium && (
                            <Td textAlign={"center"}>
                              <Icon
                                as={category?.foldable_canopy ? FiCheck : FiX}
                              />
                            </Td>
                          )}
                        </Tr>
                        <Tr>
                          <Td>{"Max. Number of Launching/Hauling"}</Td>
                          <Td
                            textAlign={"center"}
                            colSpan={category?.rate_premium ? 1 : 2}
                          >
                            {category?.max_launching_hauling_standard}
                          </Td>
                          {category?.rate_premium && (
                            <Td textAlign={"center"}>
                              {category?.max_launching_hauling_premium}
                            </Td>
                          )}
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>

                  <BrandButton
                    isDisabled={
                      stack.status === "reserved" || stack.status === "occupied"
                    }
                    onClick={bookOnlineHandler}
                    w={"auto"}
                    mt={4}
                  >
                    {stack.status === "reserved"
                      ? "Reserved"
                      : stack.status === "occupied"
                      ? "Occupied"
                      : "Book This Berth Online"}
                  </BrandButton>

                  <Box my={10}>
                    <Heading
                      as={"h3"}
                      fontSize={"md"}
                    >
                      {"Terms & Conditions"}
                    </Heading>
                    <UnorderedList fontSize={"xs"}>
                      {terms.terms?.map((term, index) => (
                        <ListItem key={index}>{term.term}</ListItem>
                      ))}
                    </UnorderedList>
                  </Box>
                </VStack>
              </GridItem>
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </GridItem>
  )
}

export default StackGridItem
