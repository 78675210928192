import { Tab } from "@chakra-ui/react"
import { TabProps } from "@chakra-ui/tabs"

const TabButton = ({ children, ...props }: TabProps) => {
  return (
    <Tab
      color={"white"}
      bgColor={"brand.300"}
      _selected={{
        bgColor: "brand.800",
      }}
      _hover={{
        bgColor: "brand.800",
      }}
      mx={4}
      transition={"all 0.3s ease-in-out"}
      {...props}
    >
      {children}
    </Tab>
  )
}

export default TabButton
