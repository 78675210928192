import { Grid, useToken } from "@chakra-ui/react"
import SpecialGridItem from "@components/Stacks/SpecialGridItem"
import StackGridItem from "@components/Stacks/StackGridItem"
import StartScrolling from "@components/Stacks/StartScrolling"
import { Berths, BerthTerms } from "@type/directus"

type StarboardStacksProps = {
  berths: Berths[][]
  terms: BerthTerms
}
const StarboardStacks = ({ berths, terms }: StarboardStacksProps) => {
  const [brand300, gray100] = useToken("colors", ["brand.300", "gray.100"])
  return (
    <>
      <Grid
        gridTemplateColumns={"repeat(3, 1fr)"}
        gap={"1"}
        pb={16}
        overflowX={"scroll"}
        sx={{
          // Firefox
          scrollbarColor: `${brand300} ${gray100}`,
          // Chrome + Safari
          "&::-webkit-scrollbar": {
            height: 2,
            bgColor: "gray.100",
          },
          "&::-webkit-scrollbar-thumb": {
            bgColor: "brand.300",
          },
        }}
      >
        <Grid
          gridTemplateRows={"repeat(4, 1fr)"}
          gridTemplateColumns={"repeat(8, 1fr)"}
          gap={"1"}
        >
          <SpecialGridItem
            gridColumnStart={1}
            gridColumnEnd={3}
            gridRowStart={4}
            gridRowEnd={5}
          >
            {"Security Room"}
          </SpecialGridItem>
          {berths[0].map((stack, index) => (
            <StackGridItem
              key={index}
              stack={stack}
              terms={terms}
            />
          ))}
        </Grid>
        <Grid
          gridTemplateRows={{ base: "140px 100px 1fr", md: "200px 140px 1fr" }}
          gridTemplateColumns={"repeat(8, 1fr)"}
          gap={"1"}
        >
          {berths[1].map((stack, index) => (
            <StackGridItem
              key={index}
              stack={stack}
              terms={terms}
              w={{ base: 20, md: 32 }}
            />
          ))}
        </Grid>
        <Grid
          gridTemplateRows={"repeat(4, 1fr)"}
          gridTemplateColumns={"repeat(4, 1fr)"}
          gap={"1"}
        >
          <SpecialGridItem
            gridColumnStart={3}
            gridColumnEnd={5}
            gridRowStart={4}
            gridRowEnd={5}
          >
            {"WC"}
          </SpecialGridItem>
          {berths[2].map((stack, index) => (
            <StackGridItem
              key={index}
              stack={stack}
              terms={terms}
            />
          ))}
        </Grid>
      </Grid>
      <StartScrolling />
    </>
  )
}

export default StarboardStacks
