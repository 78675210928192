import { Box, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import Legends from "@components/Stacks/Legends"
import PortStacks from "@components/Stacks/Port"
import StarboardStacks from "@components/Stacks/Starboard"
import TabButton from "@components/Stacks/TabButton"
import { BerthCategories, Berths, BerthTerms } from "@type/directus"

type StackTabsProps = {
  portBerths: Berths[]
  starboardBerths: Berths[][]
  terms: BerthTerms
  categories: BerthCategories[]
}

const StackTabs = ({
  portBerths,
  starboardBerths,
  terms,
  categories,
}: StackTabsProps) => {
  return (
    <Box my={10}>
      <Tabs
        variant={"unstyled"}
        colorScheme={"brand"}
        isFitted
      >
        <TabList>
          <TabButton>{"Port"}</TabButton>
          <TabButton>{"Starboard"}</TabButton>
        </TabList>
        <TabPanels>
          <TabPanel>
            <PortStacks
              berths={portBerths}
              terms={terms}
            />
          </TabPanel>
          <TabPanel>
            <StarboardStacks
              berths={starboardBerths}
              terms={terms}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Legends categories={categories} />
    </Box>
  )
}

export default StackTabs
