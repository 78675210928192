import { Box, Flex, Icon } from "@chakra-ui/react"
import { BsArrowRight } from "react-icons/bs"

const StartScrolling = () => {
  return (
    <Flex alignItems={"center"}>
      <Box as={"span"}>{"Scroll"}</Box>
      <Icon
        as={BsArrowRight}
        w={6}
        h={6}
        ms={2}
      />
    </Flex>
  )
}

export default StartScrolling
