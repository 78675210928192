import { GridItem, GridItemProps, useToken } from "@chakra-ui/react"

const SpecialGridItem = ({ children, ...props }: GridItemProps) => {
  const [gray100] = useToken("colors", ["gray.100"])
  return (
    <GridItem
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      bg={`repeating-linear-gradient(-45deg, ${gray100} 0px, ${gray100} 10px, transparent 10px, transparent 20px, ${gray100} 20px);`}
      color={"black"}
      fontSize={"sm"}
      minW={{ base: 16, md: 32 }}
      w={"full"}
      {...props}
    >
      {children}
    </GridItem>
  )
}

export default SpecialGridItem
