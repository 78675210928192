import H1 from "@components/Headings/H1"
import Layout from "@components/Layouts"
import StackTabs from "@components/Stacks/Tabs"
import { readItem, readItems } from "@directus/sdk"
import { directusClient } from "@utils/directus"
import { GetStaticProps, InferGetStaticPropsType, NextPage } from "next"

const Home: NextPage = ({
  portBerths,
  starboardBerths,
  drystackTermsData,
  drystackCategoriesData,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  return (
    <Layout bgColor={"white"}>
      <H1>{"Dry Stack Berthing"}</H1>
      <StackTabs
        portBerths={portBerths}
        starboardBerths={starboardBerths}
        terms={drystackTermsData}
        categories={drystackCategoriesData}
      />
    </Layout>
  )
}

export default Home

export const getStaticProps: GetStaticProps = async () => {
  const client = directusClient

  client.setToken(`${process.env.DIRECTUS_TOKEN}`)

  // Port Berths

  const berths = await client.request(
    readItems("berths", {
      fields: ["id", "name", { category: ["*"] }, "status", "side"],
    })
  )

  const portBerthsData = berths.filter((berth) => berth.side === "port")

  const pr = portBerthsData
    .filter((berth) => berth.name?.startsWith("PR"))
    .sort((a, b) => a.name?.localeCompare(b.name))

  const py = portBerthsData
    .filter((berth) => berth.name?.startsWith("PY"))
    .sort((a, b) => a.name?.localeCompare(b.name))

  const pb = portBerthsData
    .filter((berth) => berth.name?.startsWith("PB"))
    .sort((a, b) => a.name?.localeCompare(b.name))

  const pg = portBerthsData
    .filter((berth) => berth.name?.startsWith("PG"))
    .sort((a, b) => a.name?.localeCompare(b.name))

  const portBerths = [...pr, ...py, ...pb, ...pg]

  // Starboard Berths

  const starboardBerthsData = berths.filter(
    (berth) => berth.side === "starboard"
  )

  const sr = starboardBerthsData
    .filter((berth) => berth.name?.startsWith("SR"))
    .sort((a, b) => a.name?.localeCompare(b.name))

  const sy = starboardBerthsData
    .filter((berth) => berth.name?.startsWith("SY"))
    .sort((a, b) => a.name?.localeCompare(b.name))

  const sb = starboardBerthsData
    .filter((berth) => berth.name?.startsWith("SB"))
    .sort((a, b) => a.name?.localeCompare(b.name))

  const sg = starboardBerthsData
    .filter((berth) => berth.name?.startsWith("SG"))
    .sort((a, b) => a.name?.localeCompare(b.name))

  const sw = starboardBerthsData
    .filter((berth) => berth.name?.startsWith("SW"))
    .sort((a, b) => a.name?.localeCompare(b.name))

  const so = starboardBerthsData
    .filter((berth) => berth.name?.startsWith("SO"))
    .sort((a, b) => a.name?.localeCompare(b.name))

  const sp = starboardBerthsData
    .filter((berth) => berth.name?.startsWith("SP"))
    .sort((a, b) => a.name?.localeCompare(b.name))

  const firstStarboardArray = [
    ...sr.slice(4).reverse(),
    ...sy.slice(4).reverse(),
    ...sb.slice(4).reverse(),
    ...sg.slice(2).reverse(),
  ]
  const secondStarboardArray = [
    ...sw.reverse(),
    ...so.reverse(),
    ...sp.reverse(),
  ]
  const thirdStarboardArray = [
    ...sr.slice(0, 4).reverse(),
    ...sy.slice(0, 4).reverse(),
    ...sb.slice(0, 4).reverse(),
    ...sg.slice(0, 2).reverse(),
  ]

  const starboardBerths = [
    [...firstStarboardArray],
    [...secondStarboardArray],
    [...thirdStarboardArray],
  ]

  // Drystack Terms

  const drystackTermsData = await client.request(
    readItem("berth_terms", 1, {
      fields: ["terms"],
    })
  )

  // Drystack Categories

  const drystackCategoriesData = await client.request(
    readItems("berth_categories", {
      fields: ["*.*"],
    })
  )

  return {
    props: {
      portBerths,
      starboardBerths,
      drystackTermsData,
      drystackCategoriesData,
    },
  }
}
