import { Grid, useToken } from "@chakra-ui/react"
import SpecialGridItem from "@components/Stacks/SpecialGridItem"
import StackGridItem from "@components/Stacks/StackGridItem"
import StartScrolling from "@components/Stacks/StartScrolling"
import { Berths, BerthTerms } from "@type/directus"

type PortStacksProps = {
  berths: Berths[]
  terms: BerthTerms
}
const PortStacks = ({ berths, terms }: PortStacksProps) => {
  const [brand300, gray100] = useToken("colors", ["brand.300", "gray.100"])
  return (
    <>
      <Grid
        gridTemplateRows={"repeat(4, 1fr)"}
        gridTemplateColumns={"repeat(20, 1fr)"}
        gap={"1"}
        pb={16}
        overflowX={"scroll"}
        sx={{
          // Firefox
          scrollbarColor: `${brand300} ${gray100}`,
          // Chrome + Safari
          "&::-webkit-scrollbar": {
            height: 2,
            bgColor: "gray.100",
          },
          "&::-webkit-scrollbar-thumb": {
            bgColor: "brand.300",
          },
        }}
      >
        <SpecialGridItem
          gridColumnStart={1}
          gridColumnEnd={3}
          gridRowStart={4}
          gridRowEnd={5}
        >
          {"Operator Room"}
        </SpecialGridItem>
        <SpecialGridItem
          gridColumnStart={17}
          gridColumnEnd={21}
          gridRowStart={3}
          gridRowEnd={5}
        >
          {"Marina Office"}
        </SpecialGridItem>
        {berths.map((stack, index) => (
          <StackGridItem
            key={index}
            stack={stack}
            terms={terms}
          />
        ))}
      </Grid>
      <StartScrolling />
    </>
  )
}

export default PortStacks
